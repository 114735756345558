<template>
  <span v-if="info">
    <div :class="classes">
      <div class="icon"><i :class="iconClass"></i></div>
      <div class="information">
        <div class="name" v-b-tooltip.hover :title="info.name">
          {{ info.name }}
        </div>
        <div class="filesize" v-if="info.size">
          {{ bytesToSize(info.size) }}
        </div>
        <div class="menu" v-if="isUploaded && !isError">
          <b-dropdown size="sm" toggle-class="no-border" no-caret>
            <template #button-content>
              <i class="fa-regular fa-ellipsis clickable"></i>
            </template>
            <b-dropdown-item href="#" @click="Download" v-if="showDownload">{{
              $t("DOWNLOAD")
            }}</b-dropdown-item>
            <b-dropdown-divider v-if="showDownload"></b-dropdown-divider>
            <b-dropdown-item variant="danger" href="#" @click="Remove">{{
              $t("REMOVE")
            }}</b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="remove" v-if="isError">
          <i
            class="fal fa-trash-alt pr-2 clickable"
            @click="Remove(index)"
          ></i>
        </div>
      </div>
      <b-progress
        v-if="percentage > 0 && percentage < 100"
        :value="percentage"
        :max="100"
        show-progress
        animated
      ></b-progress>
    </div>
  </span>
</template>
<script>
export default {
  props: {
    item: {
      default: null,
      type: [Object, File],
    },
    index: {
      default: 0,
      type: Number,
    },
    percentage: {
      default: 0,
      type: Number,
    },
    showDownload: {
      type: Boolean,
      default: true
    },
    isError: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    isUploaded: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    uploading: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
  },
  data() {
    return {
      loading: false,
      info: this.item,
      blob: null,
    };
  },
  methods: {
    Remove() {
      this.$emit("RemoveAttachment", this.item);
    },
    Download() {
      this.$emit("DownloadAttachment", this.item);
    },
  },
  computed: {
    iconClass() {
      if (this.uploading && this.isError == false) {
        return ["fal", "fa-spinner"];
      }
      return ["fal", "fa-file"];
    },
    classes() {
      if (this.isError) {
        return ["attachment", "attachment-upload-error"];
      } else {
        if (this.percentage == 100) {
          return ["attachment", "attachment-upload-done"];
        }
        return ["attachment"];
      }
    },
  },
};
</script>