<template>
    <section>
        <div ref="drag" class="fileselect" :class="{over: isOver}">
            <b-jumbotron @drop.prevent="DragFile" @dragenter.prevent @dragover.prevent class="mb-0">
                <i class="fa-light fa-arrow-up-from-bracket mr-2"></i> {{ $t('DRAG_DROP_FILES') }}                
                <span @click="ShowUpload" class="clickable green"><i class="fal fa-plus"></i> {{ $t("ADD_FILE") }} </span>
                <b-form-text v-if="totalSize">
                    {{ $t("ATTACHMENTS_SIZE_LIMIT", { filesize: maxSize + "" }) }}
                </b-form-text>      
                <b-form-text v-if="!totalSize">
                    {{ $t("ATTACHMENTS_PER_SIZE_LIMIT", { filesize: maxSize + "" }) }}
                </b-form-text>    
            </b-jumbotron>
        </div>
        <input type="file" multiple v-if="fileType == ''"  />
        <input type="file" multiple v-if="fileType != ''" :accept="fileType" />
    </section>
</template>
<script>
export default {
    props: {
        value: Array,    
        totalSize: {
            type: Boolean,
            default: false
        },    
        fileType: {
            default: "",
            type: String,
        },
        maxSize: {
            type: [String, Number],
            default: ""
        },
        showDrop: {
            type: Boolean,
            validator(value) {
                return value != undefined;
            },
        }
    },    
    data() {
        return {
            isOver: false,
            isFocus: false,
            items: [],
            files: this.value,
            input: null
        };        
    },
    methods: {
        DragFile(e) {
            console.log("DragFile");
            let files = e.dataTransfer.files;
            for (let file of files) {
                if(this.CheckExistingFileBeforeAdding(file)){
                    this.files.push(file);
                }
            }
            this.isOver = false;
            this.input.value = null;
            this.$emit('input', this.files);
            this.$emit('added');
        },
        CheckExistingFileBeforeAdding(checkFile){
            for (let file of this.files) {
                if(checkFile.name == file.name)
                {
                    return false;
                }
            }
            return true;
        },
        OnFileSelection() {
            for (let file of this.input.files) {
                if(this.CheckExistingFileBeforeAdding(file)){
                    this.files.push(file);
                }
            }
            this.isOver = false;
            this.input.value = null;
            this.$emit('input', this.files);
            this.$emit('added');
        },
        ShowUpload() {
            const event = new MouseEvent('click', {
                'view': window,
                'bubbles': false,
                'cancelable': true
            });
            this.input.dispatchEvent(event);
        }
    },
    watch: {
        value(files) {
            this.files = files;
            /*this.$emit('input', files);
            this.$emit('added');*/
        }
    },
    mounted() {
        this.input = this.$el.querySelector('input[type=file]');
        this.input.addEventListener('change', () => this.OnFileSelection())
        this.input.style.display = 'none'
        this.input.setAttribute('multiple', 'multiple');
        this.$refs.drag.addEventListener("dragover", () => { this.isOver = true; });
        this.$refs.drag.addEventListener("dragleave", () => { this.isOver = false; }); 
    },

}
</script>
<style>
</style>