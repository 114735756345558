var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("b-form-textarea", {
        ref: "messageContent",
        staticClass: "mt-2 p-2 textarea",
        attrs: {
          id: "messageContent",
          placeholder: _vm.$t("CONTENT"),
          "input-id": "message",
          autocomplete: "off",
          rows: _vm.rows,
          state: _vm.TextState,
          "max-rows": _vm.rows,
        },
        model: {
          value: _vm.body,
          callback: function ($$v) {
            _vm.body = $$v
          },
          expression: "body",
        },
      }),
      _c("div", { staticClass: "clearfix" }, [
        _c(
          "div",
          { staticClass: "float-left" },
          [
            _c("b-form-text", { attrs: { id: "ENCRYPTED_BODY" } }, [
              _vm._v("\n      " + _vm._s(_vm.$t("ENCRYPTED_BODY")) + "\n    "),
            ]),
            _vm.body.length > _vm.limit
              ? _c("div", { staticClass: "error-text" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("ERROR.TOO_MANY_CHARACTERS")) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "float-right" }, [
          _c("div", { staticClass: "text-right" }, [
            _c("small", { staticClass: "pull-right" }, [
              _vm._v(_vm._s(_vm.body.length) + "/" + _vm._s(_vm.limit)),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }