var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c(
      "div",
      { ref: "drag", staticClass: "fileselect", class: { over: _vm.isOver } },
      [
        _c(
          "b-jumbotron",
          {
            staticClass: "mb-0",
            on: {
              drop: function ($event) {
                $event.preventDefault()
                return _vm.DragFile.apply(null, arguments)
              },
              dragenter: function ($event) {
                $event.preventDefault()
              },
              dragover: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c("i", { staticClass: "fa-light fa-arrow-up-from-bracket mr-2" }),
            _vm._v(
              " " +
                _vm._s(_vm.$t("DRAG_DROP_FILES")) +
                "                \n            "
            ),
            _c(
              "span",
              { staticClass: "clickable green", on: { click: _vm.ShowUpload } },
              [
                _c("i", { staticClass: "fal fa-plus" }),
                _vm._v(" " + _vm._s(_vm.$t("ADD_FILE")) + " "),
              ]
            ),
            _vm.totalSize
              ? _c("b-form-text", [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.$t("ATTACHMENTS_SIZE_LIMIT", {
                          filesize: _vm.maxSize + "",
                        })
                      ) +
                      "\n            "
                  ),
                ])
              : _vm._e(),
            !_vm.totalSize
              ? _c("b-form-text", [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.$t("ATTACHMENTS_PER_SIZE_LIMIT", {
                          filesize: _vm.maxSize + "",
                        })
                      ) +
                      "\n            "
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _vm.fileType == ""
      ? _c("input", { attrs: { type: "file", multiple: "" } })
      : _vm._e(),
    _vm.fileType != ""
      ? _c("input", {
          attrs: { type: "file", multiple: "", accept: _vm.fileType },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }